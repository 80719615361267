import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChartBar,
  faCompass,
  faImage,
  faObjectGroup,
  faSun,
} from "@fortawesome/free-regular-svg-icons"
import { faChild } from "@fortawesome/free-solid-svg-icons"

export default function MoreAbout() {
  return (
    <section id="moreAbout" className="bg-gray-800 text-white pt-4 pb-6">
      <div className="bg-gray-800 shape"></div>
      <div className="container mx-auto flex flex-wrap z-10 relative">
        <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center ">
          I'm a generalist
        </h2>
        <div className="w-full mb-8">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <div className="w-full md:w-1/3 xl:w-1/6 p-2 pt-0 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 rounded-sm overflow-hidden p-2 text-center">
            <FontAwesomeIcon icon={faChild} className="text-3xl mb-3 " />
            <h3 className="w-full font-bold text-xl text-flamayellow">
              Family
            </h3>
            <p className=" text-base mb-5">
              Mom, wife, pet owner and family photographer.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/3 xl:w-1/6 p-2 pt-0 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 rounded-sm overflow-hidden p-2 text-center">
            <FontAwesomeIcon icon={faSun} className="text-3xl mb-3" />
            <h3 className="w-full font-bold text-xl text-flamayellow">
              Origins
            </h3>
            <p className=" text-base mb-5">
              I'm Brazilian/Japanese, from{" "}
              <a
                href="https://en.wikipedia.org/wiki/Recife"
                target="_blank"
                className="font-bold underline"
              >
                Recife
              </a>
              , living in the US.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/3 xl:w-1/6 p-2 pt-0 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 rounded-sm overflow-hidden p-2 text-center">
            <FontAwesomeIcon icon={faCompass} className="text-3xl mb-3" />
            <h3 className="w-full font-bold text-xl text-flamayellow">
              Explorer
            </h3>
            <p className=" text-base mb-5">
              Learning and experiencing different cultures.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/3 xl:w-1/6 p-2 pt-0 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 rounded-sm overflow-hidden p-2 text-center">
            <FontAwesomeIcon icon={faImage} className="text-3xl mb-3" />
            <h3 className="w-full font-bold text-xl text-flamayellow">UI</h3>
            <p className=" text-base mb-5">
              Mixing color theory, minimalism and fine arts.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/3 xl:w-1/6 p-2 pt-0 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 rounded-sm overflow-hidden p-2 text-center">
            <FontAwesomeIcon icon={faObjectGroup} className="text-3xl mb-3" />
            <h3 className="w-full font-bold text-xl text-flamayellow">UX</h3>
            <p className=" text-base mb-5">
              Making it responsive, accessible and meaningful.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/3 xl:w-1/6 p-2 pt-0 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 rounded-sm overflow-hidden p-2 text-center">
            <FontAwesomeIcon icon={faChartBar} className="text-3xl mb-3" />
            <h3 className="w-full font-bold text-xl text-flamayellow">Code</h3>
            <p className=" text-base mb-5">
              20+ years experience bringing ideas to life.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
