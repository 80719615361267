import React, { Component } from "react"

;(function (d, s, id) {
  var js
  if (d.getElementById(id)) {
    return
  }
  js = d.createElement(s)
  js.id = id
  js.src = "https://embedsocial.com/cdn/ht.js"
  d.getElementsByTagName("head")[0].appendChild(js)
})(document, "script", "EmbedSocialHashtagScript")

export default class Portfolio extends Component {
  render() {
    return (
      <section id="portfolio" className="bg-gray-100 pt-24 xl:pt-32 pb-8">
        {/* bg-white border-b py-8 */}
        <div className="pt-4 my-6">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Portfolio
          </h2>
          <div className="w-full mb-6">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>

          <div className="flex flex-wrap">
            <div className="web w-full md:w-1/2 lg:w-1/4 flex flex-col flex-grow flex-shrink gradient">
              <div
                className="embedsocial-hashtag"
                data-ref="dfac3844dff70d9221b9f7de3eaf8b52a359f6a5"
              ></div>
            </div>
          </div>
        </div>
        <div className="container mx-auto text-center">
          <p className="pb-12 leading-10">
            My photography can be found at
            <br />
            <a
              href="https://aflama.journoportfolio.com"
              target="_blank"
              className="bg-flamapink text-white text-xl p-1 px-2 rounded-md text-white focus:shadow-outline hover:bg-gray-800"
            >
              https://aflama.journoportfolio.com
            </a>{" "}
            and{" "}
            <a
              href="https://unsplash.com/@a_flama"
              target="_blank"
              className="bg-flamapink text-white text-xl p-1 px-2 rounded-md text-white focus:shadow-outline hover:bg-gray-800"
            >
              https://unsplash.com/@a_flama
            </a>
          </p>
        </div>
      </section>
    )
  }
}
