import Header from "./Header"
import About from "./About"
import MoreAbout from "./MoreAbout"
import Portfolio from "./Portfolio"
// import Projects from "./Projects"
import Contact from "./Contact"
// import Footer from "./Footer"

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <About />
        <MoreAbout />
        <Portfolio />
        <Contact />
      </main>
    </div>
  )
}

export default App
