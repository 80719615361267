import favicon from "./img/favicon.png"

export default function Nav() {
  return (
    <header
      id="header"
      className="fixed w-full z-30 top-0 bg-white/80 backdrop-blur-sm text-black border-b border-gray-300"
    >
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center text-flamapink font-bold text-3xl lg:text-4xl">
          <img src={favicon} className="favicon" />
          aflama
        </div>

        <nav id="main-menu" className="pr-4">
          <input type="checkbox" id="show-menu" role="button"></input>
          <label
            id="show-menu-label"
            htmlFor="show-menu"
            className="show-menu"
          />
          <ul id="menu">
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#portfolio">Portfolio</a>
            </li>
            <li>
              <a href="https://aflama.online/blog">Blog</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
      </div>
      {/* <hr className="border-b border-gray-100 opacity-25 my-0 py-0" /> */}
    </header>
  )
}
